/* services.js */

/**
 * BOOTSTRAP
 */
// Import components
import Alert from 'bootstrap/js/src/alert.js'
import Button from 'bootstrap/js/src/button.js'
import Carousel from 'bootstrap/js/src/carousel.js'
import Collapse from 'bootstrap/js/src/collapse.js'
import Dropdown from 'bootstrap/js/src/dropdown.js'
import Modal from 'bootstrap/js/src/modal.js'
import Offcanvas from 'bootstrap/js/src/offcanvas.js'
import Popover from 'bootstrap/js/src/popover.js'
import ScrollSpy from 'bootstrap/js/src/scrollspy.js'
import Tab from 'bootstrap/js/src/tab.js'
// import Toast from 'bootstrap/js/src/toast.js'
import Tooltip from 'bootstrap/js/src/tooltip.js'

// Assign to global window object
const bootstrap = {
    Alert,
    Button,
    Carousel,
    Collapse,
    Dropdown,
    Modal,
    Offcanvas,
    Popover,
    ScrollSpy,
    Tab,
    // Toast,
    Tooltip
};

// Import custom CSS
import './bootstrap/bootstrap.scss'; // Import Bootstrap SCSS
// Import additional styles here
// import './custom.css'; // If you have custom styles
import "../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
// Your custom JavaScript code can go here
console.log('Bootstrap is ready!');
console.log(bootstrap);

/**
 * CUSTOM COMPONENTS
 */
// Import components
import ArticleEditDrag from './services/ArticleEditDrag.js';
import BrowserDetector from './services/BrowserDetector.js';
import Catalog from './services/Catalog.js'
import CatalogApp from './services/CatalogApp.js'
import ClipboardCopy from './services/ClipboardCopy.js';
import CookieManager from './services/CookieManager.js';
import DIContainer from './services/DIContainer.js'
import DOMChangeObserver from './services/DOMChangeObserver.js'
import DragNDrop from './services/DragNDrop.js';
import DynamicDataLoader from './services/DynamicDataLoader.js';
import DynamicFormHandler from './services/DynamicFormHandler.js';
import FetchHtml from './services/FetchHtml.js';
import FetchJson from './services/FetchJson.js';
import GridManager from './services/GridManager.js';
import GridResizer from './services/GridResizer.js';
import JsCombo from './services/JsCombo.js';
import LuminousManager from './services/LuminousManager.js';
import ModalManager from './services/ModalManager.js';
import PasswordMeter from './services/PasswordMeter.js';
import ResizeHandler from './services/ResizeHandler.js';
import ScrollHandler from './services/ScrollHandler.js';
import SocialShare from './services/SocialShare.js';
import TemplateCollection from './services/TemplateCollection.js';
import TemplateEngine from './services/TemplateEngine.js';
import Waiting from './services/Waiting.js';

import './scss/BetterScrollHorizontal.scss'; // Import Bootstrap SCSS
import BetterScroll from '@better-scroll/core';
import MouseWheel from '@better-scroll/mouse-wheel';
BetterScroll.use(MouseWheel);

import TagSelector from "tagselector/src/tagselector.js";
import 'tagselector/src/tagselector.scss'; // Import Bootstrap SCSS

// Export a single instance of the DIContainer
const container = new DIContainer();

/**
 * Waiting
 */
container.register('waiting', new Waiting(), true);
console.log('Waiting is ready and registered in container!');

/**
 * BrowserDetector
 */
container.register('browserDetector', new BrowserDetector(), true);
console.log('BrowserDetector is ready and registered in container!');
const detBr = container.resolve('browserDetector');
const { name, version } = detBr.getBrowserInfo();
if (detBr.isOutdated(name, version)) {
    console.log('YOUR BROWSER IS OUTDATED');
}

/**
 * SocialShare
 */
container.register('socialShare', new SocialShare(), true);
console.log('SocialShare registered in container!');

/**
 * ResizeHandler
 */
container.register('resizeHandler', new ResizeHandler(), true);
console.log('ResizeHandler registered in container!');

/**
 * GridResizer
 *
 * @FIXME imagesLoaded is not instantiated
 */
container.registerFactory('gridResizer', (container, element, options = {}) => {
    // const imagesLoaded = container.resolve('imagesLoaded');
    const resizeHandler = container.resolve('resizeHandler');
    return new GridResizer({}, imagesLoaded, resizeHandler);
}, true);
console.log('GridResizer is registered in container!');

/**
 * PasswordMeter
 */
container.registerFactory('passwordMeter', (container) => {
    const waiting = container.resolve('waiting');
    const templateEngine = container.resolve('templateEngine');
    return new PasswordMeter(waiting, templateEngine);
}, true);
console.log('PasswordMeter is registered in container!');

/**
 * CookieManager
 */
container.register('cookieManager', new CookieManager() , true);
console.log('CookieManager registered in container!');

/**
 * TemplateEngine
 */
container.register('templateCollection', new TemplateCollection(), true);
container.registerFactory('templateEngine', (container) => {
    const collection = container.resolve('templateCollection');
    return new TemplateEngine(collection);
}, true);
console.log('TemplateEngine registered in container!');

/**
 * Catalog
 */
container.registerFactory('catalog', (container) => {
    const waiting = container.resolve('waiting');
    return new Catalog(waiting, numbro, bootstrap);
}, true);
console.log('Catalog registered in container!');

/**
 * CatalogApp
 */
container.registerFactory('catalogApp', (container) => {
    const catalog = container.resolve('catalog');
    return new CatalogApp(catalog);
}, true);
console.log('CatalogApp registered in container!');

/**
 * FetchJson
 */
container.registerFactory('fetchJson', (container) => {
    const waiting = container.resolve('waiting');
    return new FetchJson(waiting);
}, true);
console.log('FetchJson registered in container!');

/**
 * FetchHtml
 */
container.registerFactory('fetchHtml', (container) => {
    const waiting = container.resolve('waiting');
    return new FetchHtml(waiting);
}, true);
console.log('FetchJson registered in container!');

/**
 * DOMChangeObserver
 */
container.registerFactory('observer', (container) => {
    const observer = new DOMChangeObserver();
    observer.init(document.body);
    observer.attachCallback(function(node) {
        console.log('A child node has been added or removed.');
    }, false);
    return observer;
}, true);
console.log('DOMChangeObserver registered in container!');

/**
 * JsCombo
 */
container.register('jscombo', new JsCombo(), true);
console.log('JsCombo registered in container!');

/**
 * JsCombo
 */
container.register('clipboardCopy', new ClipboardCopy(), true);
console.log('ClipboardCopy registered in container!');

/**
 * BetterScroll
 */
container.registerFactory('betterScroll', (container, element, options = {}) => {
    return new BetterScroll(element, options);
}, false);
console.log('BetterScroll registered in container!');

/**
 * GridManager
 */
container.registerFactory('gridManager', (container) => {
    const gridResizer = container.resolve('gridResizer');
    return new GridManager(gridResizer);
}, true);
console.log('GridManager registered in container!');

/**
 * LuminousManager
 */
container.registerFactory('luminousManager', (container) => {
    return new LuminousManager({
        arrowNavigation: true
    });
}, true);
console.log('LuminousManager registered in container!');

/**
 * TagSelector
 */
container.registerFactory('tagSelector', (container, element, options = {}) => {
    return new TagSelector(element, options);
}, false);
console.log('TagSelector registered in container!');

/**
 * ModalManager
 */
container.registerFactory('modalManager', (container) => {
    const templateEngine = container.resolve('templateEngine');
    const fetchHtml      = container.resolve('fetchHtml');
    const waiting        = container.resolve('waiting');
    return new ModalManager(templateEngine, fetchHtml, waiting);
}, false);
console.log('ModalManager registered in container!');

/**
 * ArticleEditDrag
 */
container.registerFactory('articleEditDrag', (container) => {
    const dragNDrop = new DragNDrop('[data-dragger="item"]', '[data-dragger="draggable"]');
    return new ArticleEditDrag(dragNDrop);
}, false);
console.log('ArticleEditDrag registered in container!');

/**
 * ScrollHandler
 */
container.register('scrollHandler', new ScrollHandler(), true);
console.log('ScrollHandler registered in container!');

/**
 * DynamicDataLoader
 */
container.registerFactory('dynamicDataLoader', (container, resultProcessor) => {
    return new DynamicDataLoader(resultProcessor);
});
console.log('DynamicDataLoader registered in container!');

/**
 * DynamicFormHandler
 */
container.register('dynamicFormHandler', new DynamicFormHandler(), true);
console.log('DynamicFormHandler registered in container!');

console.log(container);

export {bootstrap};
export {container};